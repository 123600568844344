<template>
  <v-card>
    <v-layout>
      <!-- <v-system-bar color="deep-purple darken-3"></v-system-bar> -->

      <v-app-bar prominent color="primary">
        <v-app-bar-nav-icon
          variant="text"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <v-toolbar-title>CalcuLaura</v-toolbar-title>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer">
        <v-list nav>
          <v-list-item
            prepend-icon="mdi-percent"
            title="Calculadora de intereses"
            value="inbox"
            :to="{ path: '/' }"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-calculator"
            title="4 x mil"
            value="supervisors"
            :to="{ path: '/4xmil' }"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-calculator"
            title="Puntos Colombia"
            value="puntoscolombia"
            :to="{ path: '/puntoscolombia' }"
          ></v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-main>
        <v-container>
          <router-view></router-view>
        </v-container>
      </v-main>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    drawer: false,
  }),
};
</script>
