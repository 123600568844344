<template>
  <div>
    <div class="text-h6 text-center">Calculadora de 4 x mil</div>

    <v-row>
      <v-col cols="12" md="4">
        <v-text-field label="Valor" v-model="valor" prefix="$"></v-text-field>
      </v-col>
    </v-row>

    <v-table v-if="mostrarTabla">
      <tbody>
        <tr>
          <td>Valor</td>
          <td class="text-left">{{ valorFormateado }}</td>
        </tr>
        <tr>
          <td>Impuesto</td>
          <td class="text-left">{{ impuestoFormateado }}</td>
        </tr>
        <tr>
          <td>Valor + Impuesto</td>
          <td class="text-left">{{ valorMasImpuestoFormateado }}</td>
        </tr>
        <tr>
          <td>Valor - Impuesto</td>
          <td class="text-left">{{ valorMenosImpuestoFormateado }}</td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valor: 1000,
      impuesto: null,
      mostrarTabla: false,
      valorFormateado: null,
      impuestoFormateado: null,
      valorMasImpuestoFormateado: null,
      valorMenosImpuestoFormateado: null,
    };
  },
  watch: {
    valor() {
      this.calcular();
    },
  },
  created() {
    this.calcular();
  },
  methods: {
    calcular() {
      var colombiaLocale = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        currencyDisplay: "symbol",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });

      this.impuesto = null;
      this.mostrarTabla = this.valor != null && this.valor > 0;

      if (this.mostrarTabla) {
        this.valor = this.valor * 1;
        this.impuesto = (this.valor * 4) / 1000;
        this.impuestoFormateado = colombiaLocale.format(this.impuesto);

        this.valorFormateado = colombiaLocale.format(this.valor);

        var _valorMasImpuesto = this.valor + this.impuesto;
        this.valorMasImpuestoFormateado =
          colombiaLocale.format(_valorMasImpuesto);

        var _valorMenosImpuesto = this.valor - this.impuesto;
        this.valorMenosImpuestoFormateado =
          colombiaLocale.format(_valorMenosImpuesto);
      }
    },
  },
};
</script>

<style>
</style>