import { createApp } from "vue";
import App from "./App.vue";

// Vuetify
import "vuetify/styles";

import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";

import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import { createRouter, createWebHashHistory } from 'vue-router'

import CalculadoraInteresesTarjeta from "./components/CalculadoraInteresesTarjeta.vue";
import CuatroXMil from "./components/CuatroXMil.vue";
import PuntosColombia from "./components/PuntosColombia.vue";

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
});

const routes = [
  { path: "/", component: CalculadoraInteresesTarjeta },
  { path: "/4xmil", component: CuatroXMil },
  { path: "/puntoscolombia", component: PuntosColombia },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

const app = createApp(App);

app.use(vuetify);
app.use(router);

app.mount("#app");
