<template>
  <div>
    <div class="text-h6 text-center">Puntos Colombia</div>

    <v-row>
      <v-col cols="12" md="4">
        <v-text-field label="Valor" v-model="valor" prefix="$"></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <div>Valor de compra: {{ valorFormateado }}</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <div>Puntos: {{ puntos }}</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <div>Puntos en pesos: {{ puntosEnPesos }}</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <div>Porcentaje cashback: {{ porcentajeCashback * 100 }} %</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valor: 1000000,
      valorFormateado: 0,
      puntos: 0,
      puntosEnPesos: 0,
      porcentajeCashback: 0.0075,
    };
  },
  watch: {
    valor() {
      this.calcular();
    },
  },
  created() {
    this.calcular();
  },
  methods: {
    calcular() {
      var colombiaLocale = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        currencyDisplay: "symbol",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });

      //   this.puntos = this.valor / (4800 / 6);

      this.puntos = (this.valor * this.porcentajeCashback) / 6;

      this.puntosEnPesos = this.valor * this.porcentajeCashback;

      this.puntosEnPesos = colombiaLocale.format(parseInt(this.puntosEnPesos));
      this.valorFormateado = colombiaLocale.format(parseInt(this.valor));
    },
  },
};
</script>

<style></style>
