<template>
  <div>
    <div class="text-h6 text-center">Calculadora de intereses</div>

    <div class="pb-6"></div>

    <v-row>
      <v-col cols="12" md="4">
        <v-text-field label="Compra" v-model="compra" prefix="$"></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
          label="Interés"
          v-model="interes"
          suffix="%"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field label="Cuotas" v-model="cuotas"></v-text-field>
      </v-col>
    </v-row>

    <v-table v-if="mostrarTabla">
      <tbody>
        <tr>
          <td>Compra</td>
          <td>{{ compraFormateada }}</td>
        </tr>
        <tr>
          <td>Base cuota</td>
          <td>{{ baseCuotaFormateada }}</td>
        </tr>
        <tr>
          <td>Total de intereses</td>
          <td>{{ totalIntereses }}</td>
        </tr>
        <tr>
          <td>Compra con intereses</td>
          <td>{{ totalCompraConIntereses }}</td>
        </tr>
      </tbody>
    </v-table>

    <div class="pb-6"></div>

    <v-table v-if="mostrarTabla">
      <thead>
        <tr>
          <th class="text-left">Nro. Cuota</th>
          <th class="text-left">Valor cuota</th>
          <th class="text-left">Interés</th>
          <th class="text-left">Capital</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in datosTabla" :key="item.cuota">
          <td>{{ item.cuota }}</td>
          <td>{{ item.valor }}</td>
          <td>{{ item.interes }}</td>
          <td>{{ item.capital }}</td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      compra: 100000,
      interes: 2.55,
      cuotas: 3,
      baseCuota: 0,
      mostrarTabla: false,
      datosTabla: null,
      compraFormateada: 0,
      baseCuotaFormateada: 0,
      totalIntereses: 0,
      totalCompraConIntereses: 0,
    };
  },
  watch: {
    compra() {
      this.calcular();
    },
    interes() {
      this.calcular();
    },
    cuotas() {
      this.calcular();
    },
  },
  created() {
    this.calcular();
  },
  methods: {
    calcular() {
      var colombiaLocale = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        currencyDisplay: "symbol",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });

      this.totalIntereses = 0;

      this.cuotas = this.cuotas * 1;
      this.interes = this.interes * 1;
      this.compra = this.compra * 1;

      var compra = this.compra;
      var cuotas = this.cuotas;
      var interes = this.interes;

      this.mostrarTabla =
        compra != null &&
        compra > 0 &&
        interes != null &&
        interes > 0 &&
        cuotas != null &&
        cuotas > 0;

      if (this.mostrarTabla) {
        var baseCuota = compra / cuotas;
        this.baseCuota = baseCuota;

        var tabla = [];
        for (let i = 0; i < cuotas; i++) {
          var capital = compra - baseCuota * cuota;

          var cuota = i + 1;
          var valorCuota = this.baseCuota;
          var interesMes = (capital * interes) / 100;

          if (cuota == 1) {
            capital = compra;
            interesMes = 0;
          } else if (cuota == 2) {
            var interes1 = (compra * interes) / 100;
            interesMes = interes1 + interesMes;
          }

          valorCuota = valorCuota + interesMes;

          this.totalIntereses = this.totalIntereses + interesMes;

          var _valorCuota = colombiaLocale.format(parseInt(valorCuota));
          var _interesMes = colombiaLocale.format(parseInt(interesMes));
          var _capital = colombiaLocale.format(parseInt(capital));

          tabla[i] = {
            cuota: cuota,
            valor: _valorCuota,
            interes: _interesMes,
            capital: _capital,
          };
        }
        this.datosTabla = tabla;
        this.totalCompraConIntereses = compra + this.totalIntereses;
        this.totalIntereses = colombiaLocale.format(
          parseInt(this.totalIntereses)
        );
        this.totalCompraConIntereses = colombiaLocale.format(
          parseInt(this.totalCompraConIntereses)
        );
        this.baseCuotaFormateada = colombiaLocale.format(
          parseInt(this.baseCuota)
        );
        this.compraFormateada = colombiaLocale.format(parseInt(this.compra));
      }
    },
  },
};
</script>

<style>
</style>